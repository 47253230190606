import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "" || localStorage.getItem("token"),
    openid: "" || localStorage.getItem("openid")
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getOpenid(state) {
      return state.openid;
    }
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
      localStorage.setItem("token", value);
    },
    delToken(state) {
      state.token = "";
      localStorage.removeItem("token");
    },
    setOpenid(state, value) {
      state.openid = value;
      localStorage.setItem("openid", value);
    }
  }
});
