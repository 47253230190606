import http from "@/utils/http";
import wx from "weixin-js-sdk";

//const appid = process.env.VUE_APP_ID;
const appid = "wx13ced5b6f84e032e";
const authUrl =
  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid;

/**
 * 微信网页授权-静默方式
 */
function authorize() {
  console.log("wx authorize start...");
  let url =
    authUrl + "&redirect_uri=" + encodeURIComponent(window.location.href);
  window.location.href =
    url + "&response_type=code&scope=snsapi_base#wechat_redirect";
}

const wxJsapi = {
  init() {
    http.get(
      "/wx/jsapi/sign",
      {
        url: location.href.split("#")[0]
      },
      res => {
        let data = res.data;
        wx.config({
          beta: true,
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.signature,
          jsApiList: ["checkJsApi", "scanQRCode", "configWXDeviceWiFi"]
        });
        wx.error(function(res) {
          //console.log("wx error: " + res);
          alert("wx.error: " + JSON.stringify(res));
        });
        wx.ready(function() {
          wx.checkJsApi({
            jsApiList: ["scanQRCode", "configWXDeviceWiFi"],
            success: function(res) {
              console.log("wx checkJsApi: " + res);
            }
          });
        });
      }
    );
  },
  scan(callback) {
    //this.init();
    wx.scanQRCode({
      // 默认为0，扫描结果由微信处理，1则直接返回扫描结果
      needResult: 1,
      scanType: ["qrCode"],
      success: function(res) {
        // 当needResult为1时，扫码返回的结果
        if (callback) {
          callback(res);
        }
      }
    });
  },
  configWiFi(callback) {
    //this.init();
    wx.invoke("configWXDeviceWiFi", {}, function(res) {
      let err_msg = res.err_msg;
      if (err_msg == "configWXDeviceWiFi:ok") {
        console.log("配网成功");
      } else if (err_msg == "configWXDeviceWiFi:cancel") {
        console.log("用户取消");
      } else {
        // configWXDeviceWiFi:fail
        console.log("配网失败");
        alert("配网失败: " + JSON.stringify(res));
      }
      if (callback) {
        callback(err_msg.replace("configWXDeviceWiFi:", ""));
      }
    });
  }
};

export default { authorize, wxJsapi };
