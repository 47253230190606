import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Base from "@/utils/base";
import wxmp from "@/utils/wxmp";
import http from "@/utils/http";

Vue.use(VueRouter);

const routes = [
  /*{
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },*/
  {
    path: "/awgels",
    name: "Awgels_Index",
    component: () => import("../views/awgels/Index.vue")
  },
  {
    path: "/awgels/panel/:deviceNo",
    name: "Awgels_Panel",
    component: () => import("../views/awgels/Panel.vue")
  },
  {
    path: "/awgels/device/:deviceNo",
    name: "Awgels_Device",
    component: () => import("../views/awgels/Device.vue")
  },
  {
    path: "/awgels/network/:deviceNo",
    name: "Awgels_Network",
    component: () => import("../views/awgels/Network.vue")
  },
  {
    path: "/awgels/record/:deviceNo",
    name: "Awgels_Record",
    component: () => import("../views/awgels/Record.vue")
  },
  {
    path: "/awgels/statistics/:deviceNo",
    name: "Awgels_Statistics",
    component: () => import("../views/awgels/Statistics.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  let token = store.state.token;
  if (token) {
    next();
  } else {
    // url包含code表示微信公众号网页授权回调，否则做网页授权
    let code = Base.getQueryString("code");
    if (!code) {
      wxmp.authorize();
    } else {
      http.get("/api/login", { code: code }, res => {
        if (res.code != 200) {
          return Promise.reject({
            status: res.code,
            message: res.message
          });
        } else {
          // 登录成功写入token信息
          store.commit("setToken", res.data.token);
          store.commit("setOpenid", res.data.openid);
          console.log("openid", res.data.openid)
          console.log("storelogopenid:", store.state.openid)
          // http://xxxx/?code=123456&state=#/awgels
          let url = window.location.href;
          // 去除?code=123456&state=后重新跳转
          window.location.href = url.replace(window.location.search, "");
        }
      });
    }
  }
});

export default router;
